
import './App.css';
import HomeRumah from './homeRumah';

function App() {
  return (
    <HomeRumah/>
  );
}

export default App;
