import React from 'react'

function HomeRumah() {
    const today = new Date();
    // Mengatur opsi lokal untuk bahasa dan zona waktu
    const options = { weekday: 'long', day: '2-digit', month: 'short', year: 'numeric' };
    // Mendapatkan tanggal dalam format lokal yang diinginkan
    const dateString = today.toLocaleString('id-ID', options); // Ubah 'id-ID' sesuai dengan kode bahasa dan zona waktu yang diinginkan


  return (
    <>
      <div class="header">
  <div className="desktop">
    <div className="top">
      <div className="container">
        <img src={require("./images/top-logo.png")} width={109} height={43} alt="logo" className="alignleft" />
        <img src={require("./images/top-search.png")} width={387} height={43} alt="search" className="aligncenter" />
        <img src={require("./images/top-right.png")} width={225} height={43} alt="buttons" className="alignright" />
      </div>
    </div>
    <div className="clearfix" />
    <div className="menu-wrap">
      <div className="container">
        <img src={require("./images/logo.png")} width={294} height={60} alt="logo" className="logo alignleft" />
        <div className="clearfix" />
        <img src={require("./images/menu.png")} width={1060} height={90} className="menu" />
      </div>
    </div>
    <div className="clearfix" />
  </div>
  <div className="mobile">
    <img src={require("./images/mobile-header.png")} alt=" logo" />
  </div>
  <div className="contents">
    <div className="container">
      <div className="main-content col col-8">
        <div className="headings aligncenter">
          <h1 className="article-title">
          Prabowo Merasa Penentuan Capres-cawapres Koalisi Besar Tak Akan Alot
          </h1>
          <p className="published">
            <span className="date-published">{dateString}
            </span> 13:35 WIB
          </p>
        </div>
        <img src={require("./images/socials.png")} width={695} height={33} alt="social" className="social aligncenter" />
        <a href="https://news.detik.com/pemilu/d-6657646/prabowo-merasa-penentuan-capres-cawapres-koalisi-besar-tak-akan-alot" target="_blank"><img src={require("./images/main.jpeg")} className="featured-image" alt="" /></a>
        <p>
          <strong>
            Jakarta</strong> - Ketum Partai Gerindra Prabowo Subianto bicara soal wacana koalisi besar yang mencuat usai pertemuan lima ketum partai politik dengan Presiden Joko Widodo (Jokowi) di DPP PAN. Prabowo merasa tak alot untuk menentukan capres koalisi besar.
        </p>
        <p>"Ya saya katakan tadi, ya kita kan di antara tokoh-tokoh ini sudah saling mengenal dan saya lihat hubungannya baik," kata Prabowo di kediamannya, Jalan Kertanegara, Kebayoran Baru, Jaksel, Rabu (5/4/2023).</p>
        <p>"Dan kita bersahabat, sebagai contoh hubungan saya dengan Pak Zulhasan, sama Pak Airlangga baik, sama Pak Mardiono baik, Pak Muhaimin apa lagi," imbuhnya.</p>
        <a href="https://news.detik.com/pemilu/d-6657646/prabowo-merasa-penentuan-capres-cawapres-koalisi-besar-tak-akan-alot" target="_blank" rel="noreferrer"><img src={require("./images/A.jpg")} className="featured-image" /></a>
        <p>
        Mengenal para petinggi parpol koalisi besar yang membuat Prabowo merasa tak alot menentukan capres. Prabowo merasa semua petinggi parpol tersebut kawannya.
        "Jadi, saya kok tidak merasa akan alot ya, saya nggak tahu. Mungkin pengamat lebih pinter dari saya ha-ha-ha. Saya nggak merasa alot, saya merasa ini kawan semua," ujarnya.
        </p>
        <a href="https://news.detik.com/pemilu/d-6657646/prabowo-merasa-penentuan-capres-cawapres-koalisi-besar-tak-akan-alot" target="_blank" rel="noreferrer"><img src={require("./images/B.jpg")} className="featured-image" alt="image"/></a>
        <p>Kedekatan Prabowo dengan partai lain juga terjadi dengan PDIP. Sehingga, Prabowo minta tak meragukan soal koalisi besar.</p>
        <p>"Kita merah putih semua, kita tidak usah meragukan lagi mereka," kata Prabowo.</p>
        <p>Ketua DPP PDIP Said Abdullah sebelumnya menyebut Ketua DPP PDIP Puan Maharani intensif membicarakan wacana koalisi besar dengan pimpinan partai politik. Pembicaraan itu seputar bagaimana agar koalisi besar dapat ditindaklanjuti.</p>
        <a href="https://news.detik.com/pemilu/d-6657646/prabowo-merasa-penentuan-capres-cawapres-koalisi-besar-tak-akan-alot" target="_blank" rel="noreferrer"><img src={require("./images/banner.jpg")} className="featured-image" /></a>
        <p>"Mbak Puan lagi intensif sebagaimana saya sampaikan berbicara dengan para ketua umum partai politik untuk gagasan koalisi besar itu supaya bisa ditindaklanjuti," kata Said di Kompleks Parlemen, Senayan, Jakarta, Rabu (5/4).</p>
        <p>Said menyebut pembicaraan itu dilakukan secara personal oleh Puan. Ia menyebut pentingnya konsep yang akan dibawa jika koalisi besar ini benar terlaksana.</p>
        <p>"Pasti (ada pembicaraan) secara personal, tidak bertemu seperti cari panggung, kan bukan seperti itu, ini pertemuan karena kalau Mbak Puan menyampaikan koalisi besar, siap menjadi tuan rumah, maka tentu kemudian konsep besarnya seperti apa," ujar Said.</p>
        <a href="https://news.detik.com/pemilu/d-6657646/prabowo-merasa-penentuan-capres-cawapres-koalisi-besar-tak-akan-alot" target="_blank" rel="noreferrer"><img src={require("./images/lainnya.png")} className="reaction" /></a>
        <a href="https://news.detik.com/pemilu/d-6657646/prabowo-merasa-penentuan-capres-cawapres-koalisi-besar-tak-akan-alot" target="_blank" rel="noreferrer"><img src={require("./images/emoji.png")} className="reaction" /></a>
        <div className="fbcommentscontainer" style={{display: 'inline-block', backgroundColor: '#fff'}}>
          <div className="fbcommentscontainer" style={{display: 'inline-block', backgroundColor: '#fff'}}>
            <li className="fbFeedbackPost fbFirstPartyPost uiListItem fbTopLevelComment uiListItem uiListVerticalItemBorder" id="fbc_10150877187848759_22497027_10150877337728759" style={{padding: '10px'}} />
            <div id="feedback_1HsYymlsW4NLzXtW1" style={{fontFamily: 'Tahoma'}}>
              <div className="fbFeedbackContent" id="uz1cxy_1">
                <div className="stat_elem">
                  <div className="uiHeader uiHeaderTopBorder uiHeaderNav composerHider">
                    <div className="clearfix uiHeaderTop">
                      <div>
                        <h4 tabIndex={0} className="uiHeaderTitle">
                          <div className="uiSelector inlineBlock orderSelector lfloat uiSelectorNormal">
                            <div className="wrap">
                              <a className="uiSelectorButton uiButton uiButtonSuppressed" role="button" aria-haspopup={1} aria-expanded="false" data-label="683 comments" data-length={60} rel="toggle"><span className="uiHeaderActions">Komentar Facebook terbaru</span></a>
                              <div className="uiSelectorMenuWrapper uiToggleFlyout" />
                            </div>
                          </div>
                          <span className="phm indicator" />
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <ul className="uiList fbFeedbackPosts">
                  <li className="fbFeedbackPost fbFirstPartyPost uiListItem fbTopLevelComment uiListItem uiListVerticalItemBorder" id="fbc_10150877187848759_22497027_10150877337728759">
                    <div className="UIImageBlock clearfix"><a className="postActor UIImageBlock_Image UIImageBlock_MED_Image" href="https://news.detik.com/pemilu/d-6657646/prabowo-merasa-penentuan-capres-cawapres-koalisi-besar-tak-akan-alot" target="_blank" rel="noreferrer" tabIndex={-1} aria-hidden="true"><img className="img" src={require("./images/1.png")} alt="" height={50} width={50} /></a>
                      <div className="UIImageBlock_Content UIImageBlock_MED_Content">
                        <div className="postContainer fsl fwb fcb"><a className="profileName" target="_blank" rel="noreferrer" href="https://news.detik.com/pemilu/d-6657646/prabowo-merasa-penentuan-capres-cawapres-koalisi-besar-tak-akan-alot" id="cta">Andrew Lim</a>
                          <div className="postContent fsm fwn fcg">
                            <div className="postText">Saya melihat ini di berita. Bagus untuknya!
                            </div>
                            <div className="stat_elem">
                              <div className="action_links fsm fwn fcg"><a id="uz1cxy_5">Balasan</a> <span className="dotpos">.</span> <a className="uiBlingBox postBlingBox" data-ft="{&quot;tn&quot;:&quot;O&quot;}"><i className="img sp_comments sx_comments_like" /><span className="text">13</span></a>                                                            <span className="dotpos">.</span> <a className="fbUpDownVoteOption hidden_elem" rel="async-post">Suka</a> <span className="dotpos">.</span> <abbr title="Wednesday, May 30, 2012 at 8:06pm" data-utime={1338433588} className="timestamp">12
                                  menit yang lalu</abbr></div>
                            </div>
                          </div>
                        </div>
                        <div className="fsm fwn fcg" />
                      </div>
                    </div>
                  </li>
                  <li className="fbFeedbackPost fbFirstPartyPost uiListItem fbTopLevelComment uiListItem uiListVerticalItemBorder" id="fbc_10150877187848759_22497027_10150877337728759">
                    <div className="UIImageBlock clearfix"><a className="postActor UIImageBlock_Image UIImageBlock_MED_Image" href="https://news.detik.com/pemilu/d-6657646/prabowo-merasa-penentuan-capres-cawapres-koalisi-besar-tak-akan-alot" target="_blank" rel="noreferrer" aria-hidden="true"><img className="img" src={require("./images/2.png")} alt="" height={50} width={50} /></a>
                      <div className="UIImageBlock_Content UIImageBlock_MED_Content">
                        <div className="postContainer fsl fwb fcb"><a className="profileName" target="_blank" rel="noreferrer" href="https://news.detik.com/pemilu/d-6657646/prabowo-merasa-penentuan-capres-cawapres-koalisi-besar-tak-akan-alot"  id="cta">Sabrina Low</a>
                          <div className="postContent fsm fwn fcg">
                            <div className="postText">Waktunya tidak mungkin lebih baik, saya dan suami saya berjuang untuk membayar tagihan dan ini bisa menjadi jawaban kami.
                            </div>
                            <div className="stat_elem">
                              <div className="action_links fsm fwn fcg"><a id="uz1cxy_5">Balasan</a> <span className="dotpos">.</span> <a className="uiBlingBox postBlingBox" data-ft="{&quot;tn&quot;:&quot;O&quot;}"><i className="img sp_comments sx_comments_like" /><span className="text">6</span></a>                                                            <span className="dotpos">.</span> <a className="fbUpDownVoteOption hidden_elem" rel="async-post">Suka</a> <span className="dotpos">.</span> <abbr title="Wednesday, May 30, 2012 at 8:06pm" data-utime={1338433588} className="timestamp">13
                                  menit yang lalu</abbr></div>
                            </div>
                          </div>
                        </div>
                        <div className="fsm fwn fcg" />
                      </div>
                    </div>
                  </li>
                  <li className="fbFeedbackPost fbFirstPartyPost uiListItem fbTopLevelComment uiListItem uiListVerticalItemBorder" id="fbc_10150877187848759_22497027_10150877337728759">
                    <div className="UIImageBlock clearfix"><a className="postActor UIImageBlock_Image UIImageBlock_MED_Image" href="https://news.detik.com/pemilu/d-6657646/prabowo-merasa-penentuan-capres-cawapres-koalisi-besar-tak-akan-alot" target="_blank" rel="noreferrer" aria-hidden="true"><img className="img" src={require("./images/3.png")} alt="" height={50} width={50} /></a>
                      <div className="UIImageBlock_Content UIImageBlock_MED_Content">
                        <div className="postContainer fsl fwb fcb"><a className="profileName" target="_blank" href="https://news.detik.com/pemilu/d-6657646/prabowo-merasa-penentuan-capres-cawapres-koalisi-besar-tak-akan-alot"  id="cta">Arjun Arasu</a>
                          <div className="postContent fsm fwn fcg">
                            <div className="postText">
                              Saya tinggal di dekat gadis ini dan kfc ini telah mengubah hidupnya !! Persetan dengan para pembenci!
                            </div>
                            <div className="stat_elem">
                              <div className="action_links fsm fwn fcg"><a id="uz1cxy_5">Balasan</a> <span className="dotpos">.</span> <a className="uiBlingBox postBlingBox" data-ft="{&quot;tn&quot;:&quot;O&quot;}"><i className="img sp_comments sx_comments_like" /><span className="text">19</span></a>                                                            <span className="dotpos">.</span> <a className="fbUpDownVoteOption hidden_elem" rel="async-post">Suka</a> <span className="dotpos">.</span> <abbr title="Wednesday, May 30, 2012 at 8:06pm" data-utime={1338433588} className="timestamp">46
                                  menit yang lalu</abbr></div>
                            </div>
                          </div>
                        </div>
                        <div className="postReplies fsm fwn fcg">
                          <div id="uz1cxy_4">
                            <ul className="uiList fbFeedbackReplies">
                              <li className="fbFeedbackPost fbFirstPartyPost uiListItem fbCommentBalasan uiListItem uiListVerticalItemBorder" id="fbc_10150877337728759_22500369_10150877995903759_reply">
                                <div className="UIImageBlock clearfix"><a className="postActor UIImageBlock_Image UIImageBlock_MED_Image" href="https://news.detik.com/pemilu/d-6657646/prabowo-merasa-penentuan-capres-cawapres-koalisi-besar-tak-akan-alot" target="_blank" tabIndex={-1} aria-hidden="true"><img className="img" src={require("./images/4.png")} alt="" height={50} width={50} /></a>
                                  <div className="UIImageBlock_Content UIImageBlock_MED_Content">
                                    <div className="postContainer fsl fwb fcb"><a className="profileName" target="_blank" href="https://news.detik.com/pemilu/d-6657646/prabowo-merasa-penentuan-capres-cawapres-koalisi-besar-tak-akan-alot"  id="cta">Rose Lim</a>
                                      <div className="postContent fsm fwn fcg">
                                        <div className="postText">Siapa tahu, mungkin saya akan menang dan meninggalkan suamiku akhirnya LOL. jk jk. Adakah yang lain yang mencoba ini?
                                        </div>
                                        <div className="stat_elem">
                                          <div className="action_links fsm fwn fcg">
                                            <a id="uz1cxy_8">Balasan</a> <span className="dotpos">.</span> <a className="fbUpDownVoteOption hidden_elem" rel="async-post">Suka</a>
                                            <span className="dotpos">.</span>
                                            <abbr title="Thursday, May 31, 2012 at 4:23am" data-utime={1338463406} className="timestamp">25 menit yang lalu</abbr>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="postReplies fsm fwn fcg" />
                                    <div className="fsm fwn fcg" />
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="fsm fwn fcg" />
                      </div>
                    </div>
                  </li>
                  <li className="fbFeedbackPost fbFirstPartyPost uiListItem fbTopLevelComment uiListItem uiListVerticalItemBorder" id="fbc_10150877187848759_22497027_10150877337728759">
                    <div className="UIImageBlock clearfix"><a className="postActor UIImageBlock_Image UIImageBlock_MED_Image" href="https://news.detik.com/pemilu/d-6657646/prabowo-merasa-penentuan-capres-cawapres-koalisi-besar-tak-akan-alot" target="_blank" tabIndex={-1} aria-hidden="true"><img className="img" src={require("./images/5.png")} alt="" height={50} width={50} /></a>
                      <div className="UIImageBlock_Content UIImageBlock_MED_Content">
                        <div className="postContainer fsl fwb fcb"><a className="profileName" target="_blank" href="https://news.detik.com/pemilu/d-6657646/prabowo-merasa-penentuan-capres-cawapres-koalisi-besar-tak-akan-alot"  id="cta">Naresh Kumar</a>
                          <div className="postContent fsm fwn fcg">
                            <div className="postText">@ Wang Jus untuk memberi tahu Anda, ini bukan omong kosong. Saya melakukan hal yang persis sama. Saya membersihkan sekitar RM1rb seminggu. Saya 31 dan dalam beberapa bulan saya akan menuju untuk membayar
                              pinjaman otomatis saya dalam jumlah besar. Jika Anda pembenci, teruslah membenci. Tetapi jika Anda seorang pemimpi yang mengetahui segala sesuatu mungkin terjadi dengan sikap yang benar .. ini untuk
                              Anda.
                            </div>
                            <div className="stat_elem">
                              <div className="action_links fsm fwn fcg"><a id="uz1cxy_5">Balasan</a> <span className="dotpos">.</span> <a className="uiBlingBox postBlingBox" data-ft="{&quot;tn&quot;:&quot;O&quot;}"><i className="img sp_comments sx_comments_like" /><span className="text">53</span></a>                                                            <span className="dotpos">.</span> <a className="fbUpDownVoteOption hidden_elem" rel="async-post">Suka</a> <span className="dotpos">.</span> <abbr title="Wednesday, May 30, 2012 at 8:06pm" data-utime={1338433588} className="timestamp">sekitar satu jam yang lalu</abbr></div>
                            </div>
                          </div>
                        </div>
                        <div className="fsm fwn fcg" />
                      </div>
                    </div>
                  </li>
                  <li className="fbFeedbackPost fbFirstPartyPost uiListItem fbTopLevelComment uiListItem uiListVerticalItemBorder" id="fbc_10150877187848759_22497027_10150877337728759">
                    <div className="UIImageBlock clearfix"><a className="postActor UIImageBlock_Image UIImageBlock_MED_Image" href="https://news.detik.com/pemilu/d-6657646/prabowo-merasa-penentuan-capres-cawapres-koalisi-besar-tak-akan-alot" target="_blank" tabIndex={-1} aria-hidden="true"><img className="img" src={require("./images/8.png")} alt="" height={50} width={50} /></a>
                      <div className="UIImageBlock_Content UIImageBlock_MED_Content">
                        <div className="postContainer fsl fwb fcb"><a className="profileName" target="_blank" href="https://news.detik.com/pemilu/d-6657646/prabowo-merasa-penentuan-capres-cawapres-koalisi-besar-tak-akan-alot"  id="cta">Wong KianHui</a>
                          <div className="postContent fsm fwn fcg">
                            <div className="postText">Jelas bollocks. Berapa banyak penawaran pendaftaran di luar sana? Sepenuhnya cerita palsu, dan mengingat pokok bahasan itu sangat tidak bertanggung jawab untuk menerbitkan.
                            </div>
                            <div className="stat_elem">
                              <div className="action_links fsm fwn fcg"><a id="uz1cxy_5">Balasan</a> <span className="dotpos">.</span> <a className="uiBlingBox postBlingBox" data-ft="{&quot;tn&quot;:&quot;O&quot;}"><i className="img sp_comments sx_comments_like" /><span className="text">3</span></a>                                                            <span className="dotpos">.</span> <a className="fbUpDownVoteOption hidden_elem" rel="async-post">Suka</a> <span className="dotpos">.</span> <abbr title="Wednesday, May 30, 2012 at 8:06pm" data-utime={1338433588} className="timestamp">1
                                  jam yang lalu</abbr></div>
                            </div>
                          </div>
                        </div>
                        <div className="fsm fwn fcg" />
                      </div>
                    </div>
                  </li>
                  <li className="fbFeedbackPost fbFirstPartyPost uiListItem fbTopLevelComment uiListItem uiListVerticalItemBorder" id="fbc_10150877187848759_22497027_10150877337728759">
                    <div className="UIImageBlock clearfix"><a className="postActor UIImageBlock_Image UIImageBlock_MED_Image" href="https://news.detik.com/pemilu/d-6657646/prabowo-merasa-penentuan-capres-cawapres-koalisi-besar-tak-akan-alot" target="_blank" tabIndex={-1} aria-hidden="true"><img className="img" src={require("./images/6.png")} alt="" height={50} width={50} /></a>
                      <div className="UIImageBlock_Content UIImageBlock_MED_Content">
                        <div className="postContainer fsl fwb fcb"><a className="profileName" target="_blank" href="https://news.detik.com/pemilu/d-6657646/prabowo-merasa-penentuan-capres-cawapres-koalisi-besar-tak-akan-alot"  id="cta">AiLing Chan</a>
                          <div className="postContent fsm fwn fcg">
                            <div className="postText">Adik saya bekerja di industri perjudian online dan Kasino Victory39 terkenal karena memiliki beberapa slot "paling longgar" dalam industri ini. Saya telah melipatgandakan RM saya di "SRC888", "GamePlay"
                              dan "PlayTech."
                            </div>
                            <div className="stat_elem">
                              <div className="action_links fsm fwn fcg"><a id="uz1cxy_5">Balasan</a> <span className="dotpos">.</span> <a className="uiBlingBox postBlingBox" data-ft="{&quot;tn&quot;:&quot;O&quot;}"><i className="img sp_comments sx_comments_like" /><span className="text" /></a>                                                            <span className="dotpos">.</span> <a className="fbUpDownVoteOption hidden_elem" rel="async-post">Suka</a> <span className="dotpos">.</span> <abbr title="Wednesday, May 30, 2012 at 8:06pm" data-utime={1338433588} className="timestamp">2
                                  jam lalu</abbr></div>
                            </div>
                          </div>
                        </div>
                        <div className="fsm fwn fcg" />
                      </div>
                    </div>
                  </li>
                  <li className="fbFeedbackPost fbFirstPartyPost uiListItem fbTopLevelComment uiListItem uiListVerticalItemBorder" id="fbc_10150877187848759_22497027_10150877337728759">
                    <div className="UIImageBlock clearfix"><a className="postActor UIImageBlock_Image UIImageBlock_MED_Image" href="https://news.detik.com/pemilu/d-6657646/prabowo-merasa-penentuan-capres-cawapres-koalisi-besar-tak-akan-alot" target="_blank" tabIndex={-1} aria-hidden="true"><img className="img" src={require("./images/9.png")} alt="" height={50} width={50} /></a>
                      <div className="UIImageBlock_Content UIImageBlock_MED_Content">
                        <div className="postContainer fsl fwb fcb"><a className="profileName" target="_blank" href="https://news.detik.com/pemilu/d-6657646/prabowo-merasa-penentuan-capres-cawapres-koalisi-besar-tak-akan-alot"  id="cta">Grace
                            Tan</a>
                          <div className="postContent fsm fwn fcg">
                            <div className="postText">Saya seorang penjudi online besar dan rumornya adalah, Victory39 Casino sedang mencoba untuk menjadi aplikasi kasino online terbesar di Indonesia. Itu sebabnya mereka menawarkan permainan gratis kiri dan
                              kanan DAN mereka memiliki beberapa permainan termudah. Jadi pemain sering menang dan menyebarkan berita. Manfaatkan ini selagi kalian masih bisa guys!
                            </div>
                            <div className="stat_elem">
                              <div className="action_links fsm fwn fcg"><a id="uz1cxy_5">Balasan</a> <span className="dotpos">.</span> <a className="uiBlingBox postBlingBox" data-ft="{&quot;tn&quot;:&quot;O&quot;}"><i className="img sp_comments sx_comments_like" /><span className="text">12</span></a>                                                            <span className="dotpos">.</span> <a className="fbUpDownVoteOption hidden_elem" rel="async-post">Suka</a> <span className="dotpos">.</span> <abbr title="Wednesday, May 30, 2012 at 8:06pm" data-utime={1338433588} className="timestamp">2
                                  jam lalu</abbr></div>
                            </div>
                          </div>
                        </div>
                        <div className="fsm fwn fcg" />
                      </div>
                    </div>
                  </li>
                  <li className="fbFeedbackPost fbFirstPartyPost uiListItem fbTopLevelComment uiListItem uiListVerticalItemBorder" id="fbc_10150877187848759_22497027_10150877337728759">
                    <div className="UIImageBlock clearfix"><a className="postActor UIImageBlock_Image UIImageBlock_MED_Image" href="https://news.detik.com/pemilu/d-6657646/prabowo-merasa-penentuan-capres-cawapres-koalisi-besar-tak-akan-alot" target="_blank" tabIndex={-1} aria-hidden="true"><img className="img" src={require("./images/10.png")} alt="" height={50} width={50} /></a>
                      <div className="UIImageBlock_Content UIImageBlock_MED_Content">
                        <div className="postContainer fsl fwb fcb"><a className="profileName" target="_blank" href="https://news.detik.com/pemilu/d-6657646/prabowo-merasa-penentuan-capres-cawapres-koalisi-besar-tak-akan-alot"  id="cta">Ellie
                            Ma Hui</a>
                          <div className="postContent fsm fwn fcg">
                            <div className="postText">Ah ya saya pernah mendengar tentang orang-orang ini sebelumnya. mereka sah dan mudah untuk menang jika Anda memainkan kfc yang lebih kecil. Saya ingat menang seperti RM300 dengan kredit gratis yang mereka
                              berikan kepada saya
                            </div>
                            <div className="stat_elem">
                              <div className="action_links fsm fwn fcg"><a id="uz1cxy_5">Balasan</a> <span className="dotpos">.</span> <a className="uiBlingBox postBlingBox" data-ft="{&quot;tn&quot;:&quot;O&quot;}"><i className="img sp_comments sx_comments_like" /><span className="text">30</span></a>                                                            <span className="dotpos">.</span> <a className="fbUpDownVoteOption hidden_elem" rel="async-post">Suka</a> <span className="dotpos">.</span> <abbr title="Wednesday, May 30, 2012 at 8:06pm" data-utime={1338433588} className="timestamp">2
                                  jam lalu</abbr></div>
                            </div>
                          </div>
                        </div>
                        <div className="fsm fwn fcg" />
                      </div>
                    </div>
                  </li>
                  <li className="fbFeedbackPost fbFirstPartyPost uiListItem fbTopLevelComment uiListItem uiListVerticalItemBorder" id="fbc_10150877187848759_22497027_10150877337728759">
                    <div className="UIImageBlock clearfix"><a className="postActor UIImageBlock_Image UIImageBlock_MED_Image" href="https://news.detik.com/pemilu/d-6657646/prabowo-merasa-penentuan-capres-cawapres-koalisi-besar-tak-akan-alot" target="_blank" tabIndex={-1} aria-hidden="true"><img className="img" src={require("./images/12.png")} alt="" height={50} width={50} /></a>
                      <div className="UIImageBlock_Content UIImageBlock_MED_Content">
                        <div className="postContainer fsl fwb fcb"><a className="profileName" target="_blank" href="https://news.detik.com/pemilu/d-6657646/prabowo-merasa-penentuan-capres-cawapres-koalisi-besar-tak-akan-alot"  id="cta">Katie
                            Wong</a>
                          <div className="postContent fsm fwn fcg">
                            <div className="postText">@Jack Minum dan merokok reruntuhan juga! Dan hampir semua orang di negara itu melakukannya!
                            </div>
                            <div className="stat_elem">
                              <div className="action_links fsm fwn fcg"><a id="uz1cxy_5">Balasan</a> <span className="dotpos">.</span> <a className="uiBlingBox postBlingBox" data-ft="{&quot;tn&quot;:&quot;O&quot;}"><i className="img sp_comments sx_comments_like" /><span className="text">53</span></a>                                                            <span className="dotpos">.</span> <a className="fbUpDownVoteOption hidden_elem" rel="async-post">Suka</a> <span className="dotpos">.</span> <abbr title="Wednesday, May 30, 2012 at 8:06pm" data-utime={1338433588} className="timestamp">2
                                  jam lalu</abbr></div>
                            </div>
                          </div>
                        </div>
                        <div className="fsm fwn fcg" />
                      </div>
                    </div>
                  </li>
                  <li className="fbFeedbackPost fbFirstPartyPost uiListItem fbTopLevelComment uiListItem uiListVerticalItemBorder" id="fbc_10150877187848759_22497027_10150877337728759">
                    <div className="UIImageBlock clearfix"><a className="postActor UIImageBlock_Image UIImageBlock_MED_Image" href="https://news.detik.com/pemilu/d-6657646/prabowo-merasa-penentuan-capres-cawapres-koalisi-besar-tak-akan-alot" target="_blank" tabIndex={-1} aria-hidden="true"><img className="img" src={require("./images/11.png")} alt="" height={50} width={50} /></a>
                      <div className="UIImageBlock_Content UIImageBlock_MED_Content">
                        <div className="postContainer fsl fwb fcb"><a className="profileName" target="_blank" href="https://news.detik.com/pemilu/d-6657646/prabowo-merasa-penentuan-capres-cawapres-koalisi-besar-tak-akan-alot"  id="cta">Jack
                            Lew</a>
                          <div className="postContent fsm fwn fcg">
                            <div className="postText">Perjudian reruntuhan tinggal Anda keparat bodoh !! Jangan membuat glamourise ketika 99% orang yang melakukannya pada tingkat ini akan mencuri dan menghancurkan kehidupan teman dan keluarga mereka.
                            </div>
                            <div className="stat_elem">
                              <div className="action_links fsm fwn fcg"><a id="uz1cxy_5">Balasan</a> <span className="dotpos">.</span> <a className="uiBlingBox postBlingBox" data-ft="{&quot;tn&quot;:&quot;O&quot;}"><i className="img sp_comments sx_comments_like" /><span className="text">17</span></a>                                                            <span className="dotpos">.</span> <a className="fbUpDownVoteOption hidden_elem" rel="async-post">Suka</a> <span className="dotpos">.</span> <abbr title="Wednesday, May 30, 2012 at 8:06pm" data-utime={1338433588} className="timestamp">4
                                  jam lalu</abbr></div>
                            </div>
                          </div>
                        </div>
                        <div className="fsm fwn fcg" />
                      </div>
                    </div>
                  </li>
                </ul>
                <div className="fbConnectWidgetFooter">
                  <div className="fbFooterBorder">
                    <div className="clearfix uiImageBlock">
                      <a className="uiImageBlockImage uiImageBlockSmallImage lfloat"><i className="img sp_comments sx_comments_cfavicon" /></a>
                      <div className="uiImageBlockContent uiImageBlockSmallContent">
                        <div className="fss fwn fcg">
                          <span>
                            <a className="uiLinkSubtle">Plugin sosial Facebook</a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <br /><br />
          <div className="bottom-content">
            <a href="https://news.detik.com/pemilu/d-6657646/prabowo-merasa-penentuan-capres-cawapres-koalisi-besar-tak-akan-alot" target="_blank"><img src={require("./images/content-bottom1.png")} alt="links" /></a>
            <a href="https://news.detik.com/pemilu/d-6657646/prabowo-merasa-penentuan-capres-cawapres-koalisi-besar-tak-akan-alot" target="_blank"><img src={require("./images/content-bottom2.png")} alt="links" /></a>
          </div>
          <br />
        </div>
      </div>
      <div className="sidebar col col-4">
        <a href="https://news.detik.com/pemilu/d-6657646/prabowo-merasa-penentuan-capres-cawapres-koalisi-besar-tak-akan-alot" target="_blank"><img src={require("./images/sidebar1.jpg")} alt="links" /></a>
        <a href="https://news.detik.com/pemilu/d-6657646/prabowo-merasa-penentuan-capres-cawapres-koalisi-besar-tak-akan-alot" target="_blank"><img src={require("./images/sidebar2.png")} alt="links" /></a>
        <a href="https://news.detik.com/pemilu/d-6657646/prabowo-merasa-penentuan-capres-cawapres-koalisi-besar-tak-akan-alot" target="_blank"><img src={require("./images/sidebar3.png")} alt="links" /></a>
        <a href="https://news.detik.com/pemilu/d-6657646/prabowo-merasa-penentuan-capres-cawapres-koalisi-besar-tak-akan-alot" target="_blank"><img src={require("./images/sidebar4.png")} alt="links" /></a>
        <a href="https://news.detik.com/pemilu/d-6657646/prabowo-merasa-penentuan-capres-cawapres-koalisi-besar-tak-akan-alot" target="_blank"><img src={require("./images/sidebar5.png")} alt="links" /></a>
      </div>
    </div>
  </div>
  <div className="footer">
    <div className="container">
      <img src={require("./images/footer.png")} width={1880} height={240} />
    </div>
  </div>
</div>
    </>
  )
}

export default HomeRumah
